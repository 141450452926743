import { gql } from "@apollo/client";
import {
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  Link,
  NumberInput,
  NumberInputField,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { useFieldArray, useForm } from "react-hook-form";
import { Link as ReactRouterLink } from "react-router-dom";
import * as v from "valibot";
import type {
  PlatformSettingsForm_ShopMercariShopsSettingFragment,
  PlatformSettingsForm_ShopYahooShoppingSettingFragment,
} from "../graphql/generated/apollo";

gql`
  fragment PlatformSettingsForm_ShopMercariShopsSetting on shop_mercari_shops_settings {
    default_condition
    default_shipping_duration
    default_shipping_from_state_id
    default_shipping_method
  }
  fragment PlatformSettingsForm_ShopYahooShoppingSetting on shop_yahoo_shopping_settings {
    postage_sets
    lead_times
  }
`;

const schema = v.object({
  mercari_shops: v.object({
    default_condition: v.string(),
    default_shipping_duration: v.string(),
    default_shipping_from_state_id: v.string(),
    default_shipping_method: v.string(),
  }),
  yahoo_shopping: v.object({
    postage_sets: v.pipe(
      v.array(
        v.object({
          number: v.pipe(
            v.number(),
            v.minValue(1, "番号は必須です"),
            v.maxValue(20, "番号は20以下である必要があります"),
          ),
          name: v.pipe(v.string(), v.minLength(1, "名前は必須です")),
        }),
      ),
      v.minLength(1, "配送グループは1つ以上必要です"),
      v.maxLength(20, "配送グループは20個以下である必要があります"),
    ),
    lead_times: v.array(
      v.object({
        number: v.pipe(v.number(), v.minValue(1, "番号は必須です")),
        name: v.pipe(v.string(), v.minLength(1, "名前は必須です")),
      }),
    ),
  }),
});

type PlatformFormType = v.InferOutput<typeof schema>;

type PlatformSettingsFormProps = {
  initialValue?: {
    mercari_shops?: PlatformSettingsForm_ShopMercariShopsSettingFragment;
    yahoo_shopping?: PlatformSettingsForm_ShopYahooShoppingSettingFragment;
  };
  onSubmit: (data: PlatformFormType) => void;
};

export function PlatformSettingsForm({ initialValue, onSubmit }: PlatformSettingsFormProps) {
  // dataが読み込まれたら、フォームにデータをセットする
  const mercariShopsDefaultValues = initialValue?.mercari_shops ?? {
    default_condition: "",
    default_shipping_duration: "",
    default_shipping_from_state_id: "",
    default_shipping_method: "",
  };
  const yahooShoppingDefaultValues = initialValue?.yahoo_shopping ?? {
    postage_sets: [{ number: 1, name: "デフォルト設定" }],
  };
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PlatformFormType>({
    resolver: valibotResolver(schema),
    defaultValues: {
      mercari_shops: mercariShopsDefaultValues,
      yahoo_shopping: yahooShoppingDefaultValues,
    },
  });

  const {
    fields: fieldsPostageSets,
    append: appendPostageSets,
    remove: removePostageSets,
  } = useFieldArray({
    control,
    name: "yahoo_shopping.postage_sets",
  });

  const {
    fields: fieldsLeadTimes,
    append: appendLeadTimes,
    remove: removeLeadTimes,
  } = useFieldArray({
    control,
    name: "yahoo_shopping.lead_times",
  });

  return (
    <>
      <Heading fontSize="lg" textAlign="left" py={2}>
        プラットフォーム固有の設定
      </Heading>
      <Heading fontSize="md" textAlign="left" py={2}>
        メルカリShops
      </Heading>
      <FormControl isInvalid={!!errors.mercari_shops?.default_condition?.message}>
        <Heading fontSize="sm" textAlign="left" py={2}>
          デフォルトの状態
        </Heading>
        <Select {...register("mercari_shops.default_condition")}>
          <option value={""}>未選択</option>
          <option value="BRAND_NEW">新品、未使用</option>
          <option value="ALMOST_NEW">未使用に近い</option>
          <option value="CLEAN">目立った傷や汚れなし</option>
          <option value="LITTLE_DIRTY">やや傷や汚れあり</option>
          <option value="DIRTY">傷や汚れあり</option>
          <option value="BAD">全体的に状態が悪い</option>
        </Select>
        <FormErrorMessage>{errors.mercari_shops?.default_condition?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.mercari_shops?.default_shipping_method?.message}>
        <Heading fontSize="sm" textAlign="left" py={2}>
          デフォルトの配送方法
        </Heading>
        <Select {...register("mercari_shops.default_shipping_method")}>
          <option value={""}>未選択</option>
          <option value="UNDECIDED">未定（出品者が手配）</option>
          <option value="COOL">クール便</option>
          <option value="MERCARI_SHIPPING_YAMATO">らくらくメルカリ便</option>
          <option value="MERCARI_SHIPPING_YAMATO_COOL_REFRIGERATED">クールメルカリ便（冷蔵）</option>
          <option value="MERCARI_SHIPPING_YAMATO_COOL_FROZEN">クールメルカリ便（冷凍）</option>
        </Select>
        <FormErrorMessage>{errors.mercari_shops?.default_shipping_method?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.mercari_shops?.default_shipping_from_state_id?.message}>
        <Heading fontSize="sm" textAlign="left" py={2}>
          デフォルトの発送元地域
        </Heading>
        <Select {...register("mercari_shops.default_shipping_from_state_id")}>
          <option value={""}>未選択</option>
          <option value="jp01">北海道</option>
          <option value="jp02">青森県</option>
          <option value="jp03">岩手県</option>
          <option value="jp04">宮城県</option>
          <option value="jp05">秋田県</option>
          <option value="jp06">山形県</option>
          <option value="jp07">福島県</option>
          <option value="jp08">茨城県</option>
          <option value="jp09">栃木県</option>
          <option value="jp10">群馬県</option>
          <option value="jp11">埼玉県</option>
          <option value="jp12">千葉県</option>
          <option value="jp13">東京都</option>
          <option value="jp14">神奈川県</option>
          <option value="jp15">新潟県</option>
          <option value="jp16">富山県</option>
          <option value="jp17">石川県</option>
          <option value="jp18">福井県</option>
          <option value="jp19">山梨県</option>
          <option value="jp20">長野県</option>
          <option value="jp21">岐阜県</option>
          <option value="jp22">静岡県</option>
          <option value="jp23">愛知県</option>
          <option value="jp24">三重県</option>
          <option value="jp25">滋賀県</option>
          <option value="jp26">京都府</option>
          <option value="jp27">大阪府</option>
          <option value="jp28">兵庫県</option>
          <option value="jp29">奈良県</option>
          <option value="jp30">和歌山県</option>
          <option value="jp31">鳥取県</option>
          <option value="jp32">島根県</option>
          <option value="jp33">岡山県</option>
          <option value="jp34">広島県</option>
          <option value="jp35">山口県</option>
          <option value="jp36">徳島県</option>
          <option value="jp37">香川県</option>
          <option value="jp38">愛媛県</option>
          <option value="jp39">高知県</option>
          <option value="jp40">福岡県</option>
          <option value="jp41">佐賀県</option>
          <option value="jp42">長崎県</option>
          <option value="jp43">熊本県</option>
          <option value="jp44">大分県</option>
          <option value="jp45">宮崎県</option>
          <option value="jp46">鹿児島県</option>
          <option value="jp47">沖縄県</option>
        </Select>
        <FormErrorMessage>{errors.mercari_shops?.default_shipping_from_state_id?.message}</FormErrorMessage>
      </FormControl>
      <FormControl isInvalid={!!errors.mercari_shops?.default_shipping_duration?.message}>
        <Heading fontSize="sm" textAlign="left" py={2}>
          デフォルトの発送日数
        </Heading>
        <Select {...register("mercari_shops.default_shipping_duration")}>
          <option value={""}>未選択</option>
          <option value="ONE_TO_TWO_DAYS">１〜２日で発送</option>
          <option value="TWO_TO_THREE_DAYS">２〜３日で発送</option>
          <option value="FOUR_TO_SEVEN_DAYS">４〜７日で発送</option>
          <option value="EIGHT_DAYS_OR_MORE_OR_UNDECIDED">８〜９０日で発送</option>
        </Select>
        <FormErrorMessage>{errors.mercari_shops?.default_shipping_duration?.message}</FormErrorMessage>
      </FormControl>

      <Divider my={4} />

      <Heading fontSize="md" textAlign="left" py={2}>
        Yahoo!ショッピング
      </Heading>
      <FormControl isInvalid={!!errors.yahoo_shopping?.postage_sets?.root?.message}>
        <Heading fontSize="sm" textAlign="left" py={2}>
          配送グループ設定
        </Heading>

        <Table size="sm" variant="unstyled">
          <Thead>
            <Tr>
              <Th>No.</Th>
              <Th>配送グループ名</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {fieldsPostageSets.map((field, index) => (
              <Tr key={field.id}>
                <Td>
                  <FormControl isInvalid={!!errors.yahoo_shopping?.postage_sets?.[index]?.number}>
                    <NumberInput>
                      <NumberInputField
                        {...register(`yahoo_shopping.postage_sets.${index}.number`, { valueAsNumber: true })}
                      />
                    </NumberInput>
                    <FormErrorMessage>{errors.yahoo_shopping?.postage_sets?.[index]?.number?.message}</FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <FormControl isInvalid={!!errors.yahoo_shopping?.postage_sets?.[index]?.name}>
                    <Input {...register(`yahoo_shopping.postage_sets.${index}.name`)} />
                    <FormErrorMessage>{errors.yahoo_shopping?.postage_sets?.[index]?.name?.message}</FormErrorMessage>
                  </FormControl>
                </Td>
                <Td>
                  <Button type="button" onClick={() => removePostageSets(index)}>
                    削除
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
        <Button
          type="button"
          onClick={() => appendPostageSets({ number: fieldsPostageSets.length + 1, name: "" })}
          mt={2}
        >
          グループを追加
        </Button>
        <FormHelperText fontSize="xs">
          配送グループは「ストアクリエイターPro」でも設定が必要です。詳しくは
          <Link as={ReactRouterLink} to="https://test-store-info.yahoo.co.jp/store/t/376.html">
            ツールマニュアル
          </Link>
          をご覧ください
        </FormHelperText>
        <FormErrorMessage>{errors.yahoo_shopping?.postage_sets?.root?.message}</FormErrorMessage>
      </FormControl>
      <FormControl>
        <Heading fontSize="sm" textAlign="left" py={2}>
          発送日情報設定
        </Heading>
        <FormControl>
          <Table size="sm" variant="unstyled">
            <Thead>
              <Tr>
                <Th>発送日情報管理番号</Th>
                <Th>発送日表示設定名</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {fieldsLeadTimes.map((field, index) => (
                <Tr key={field.id}>
                  <Td>
                    <FormControl isInvalid={!!errors.yahoo_shopping?.lead_times?.[index]?.number}>
                      <NumberInput>
                        <NumberInputField
                          {...register(`yahoo_shopping.lead_times.${index}.number`, { valueAsNumber: true })}
                        />
                      </NumberInput>
                      <FormErrorMessage>{errors.yahoo_shopping?.lead_times?.[index]?.number?.message}</FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td>
                    <FormControl isInvalid={!!errors.yahoo_shopping?.lead_times?.[index]?.name}>
                      <Input {...register(`yahoo_shopping.lead_times.${index}.name`)} />
                      <FormErrorMessage>{errors.yahoo_shopping?.lead_times?.[index]?.name?.message}</FormErrorMessage>
                    </FormControl>
                  </Td>
                  <Td>
                    <Button type="button" onClick={() => removeLeadTimes(index)}>
                      削除
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
          <Button
            type="button"
            onClick={() => appendLeadTimes({ number: fieldsLeadTimes.length + 1, name: "" })}
            mt={2}
          >
            発送日表示設定を追加
          </Button>
        </FormControl>
        <FormHelperText fontSize="xs">
          発送日表示設定は「ストアクリエイターPro」でも設定が必要です。詳しくは
          <Link as={ReactRouterLink} to="https://test-store-info.yahoo.co.jp/store/g/312.html">
            ツールマニュアル
          </Link>
          をご覧ください
        </FormHelperText>
      </FormControl>
      <Button onClick={handleSubmit(onSubmit)} my={4}>
        保存
      </Button>
    </>
  );
}
