import { ApolloClient, ApolloProvider, InMemoryCache, createHttpLink } from "@apollo/client";
import { loadDevMessages, loadErrorMessages } from "@apollo/client/dev";
import { setContext } from "@apollo/client/link/context";
import { removeTypenameFromVariables } from "@apollo/client/link/remove-typename";
import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "./auth";
import { auth } from "./firebase";
import "./index.css";
import App from "./routes/App";
import { Apply } from "./routes/Apply";
import { Layout } from "./routes/Layout";
import { Listings } from "./routes/Listings";
import { Login } from "./routes/Login";
import { PasswordReset } from "./routes/PasswordReset";
import { Sell } from "./routes/Sell";
import { Settings } from "./routes/Settings";
import { Setup } from "./routes/Setup";
import { Callback } from "./routes/platforms/auth/Callback";
import { Platforms } from "./routes/settings/Platforms";
import "./sentry";
import { ApplyEnterprise } from "./routes/ApplyEnterprise";
import { ApplyTrial } from "./routes/ApplyTrial";
import { ApplyTrialEnterprise } from "./routes/ApplyTrialEnterprise";
import { ShopProvider } from "./shop";

if (process.env.NODE_ENV !== "production") {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const authLink = setContext((_, { headers }) => {
  const adminSecret = localStorage.getItem("hasuraAdminSecret");
  if (adminSecret) {
    return {
      headers: {
        ...headers,
        "x-hasura-admin-secret": adminSecret,
      },
    };
  }

  return (
    auth.currentUser
      ?.getIdToken()
      .then((token) => {
        if (token) {
          return {
            headers: {
              ...headers,
              authorization: `Bearer ${token}`,
            },
          };
        }
        return headers;
      })
      .catch((e) => {
        console.error("failed to getIdToken", e);
        return headers;
      }) ?? headers
  );
});

const client = new ApolloClient({
  link: authLink.concat(removeTypenameFromVariables()).concat(
    createHttpLink({
      uri: import.meta.env.VITE_GRAPHQL_ENDPOINT,
    }),
  ),
  cache: new InMemoryCache(),
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "listings/*",
    element: <Listings />,
  },
  {
    path: "sell",
    element: <Sell />,
  },
  {
    path: "sell/:id",
    element: <Sell />,
  },
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "password-reset",
    element: <PasswordReset />,
  },
  {
    path: "setup",
    element: <Setup />,
  },
  {
    path: "settings",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Settings />,
      },
      {
        path: "platforms",
        element: <Platforms />,
      },
    ],
  },
  {
    path: "platforms/:platform/auth/callback",
    element: <Callback />,
  },
  {
    path: "apply",
    element: <Apply />,
  },
  {
    path: "apply-trial",
    element: <ApplyTrial />,
  },
  {
    path: "apply-trial-enterprise",
    element: <ApplyTrialEnterprise />,
  },
  {
    path: "apply-enterprise",
    element: <ApplyEnterprise />,
  },
  {
    path: "apply-enterprise/:code",
    element: <ApplyEnterprise />,
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AuthProvider>
      <ChakraProvider>
        <ApolloProvider client={client}>
          <ShopProvider>
            <RouterProvider router={router} />
          </ShopProvider>
        </ApolloProvider>
      </ChakraProvider>
    </AuthProvider>
  </React.StrictMode>,
);
