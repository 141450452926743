const mimeToExt: Record<string, string> = {
  "image/jpeg": "jpeg",
  "image/png": "png",
  "image/gif": "gif",
  "image/webp": "webp",
  "image/heic": "heic",
  "image/heif": "heif",
  "image/avif": "avif",
};

function getExtensionFromMimeType(mimeType: string) {
  return mimeToExt[mimeType] || mimeType.split("/")[1];
}

export function filename(base: string, mimeType?: string | null) {
  return base + (mimeType ? `.${getExtensionFromMimeType(mimeType)}` : "");
}
