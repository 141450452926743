import { Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import ApplyForm from "../components/ApplyForm";

type InformationSpecially = {
  name: string;
  serviceFeePercentage?: number;
  planDescription?: string;
};

const SPECIAL_PLANS: { [key: string]: InformationSpecially } = {
  "1221b": {
    name: "ブローチ様専用",
    serviceFeePercentage: 1.7,
    planDescription:
      "※年間契約プランとなりますのでご契約から1年未満での解約の場合は、残存月数×月額利用料を一括でお支払いいただきます。",
  },
};

export function ApplyEnterprise() {
  const params = useParams<{ code: string }>();
  const code = params.code;
  const information = code ? SPECIAL_PLANS[code] : undefined;

  return (
    <div className="App">
      {information ? (
        <>
          <Heading as="h1" size="xl" marginBottom="24px" fontWeight="900" fontFamily="'Noto Sans JP', sans-serif">
            {information.name}
            <br />
            お申し込みフォーム
          </Heading>
          <ApplyForm
            trialUser={false}
            onlyEnterprisePlan={true}
            enterprisePlanName={information.name}
            serviceFeePercentage={information.serviceFeePercentage}
            planDescription={information.planDescription}
          />
        </>
      ) : (
        <>
          <Heading as="h1" size="xl" marginBottom="24px" fontWeight="900" fontFamily="'Noto Sans JP', sans-serif">
            さあ、ネットショップを
            <br />
            ラクにしよう
          </Heading>
          <ApplyForm trialUser={false} onlyEnterprisePlan={true} />
        </>
      )}
    </div>
  );
}
