import { gql } from "@apollo/client";
import { CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Input, Link, Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import {
  type IntegrationButton_IntegrationFragment,
  type IntegrationFragment,
  IntegrationFragmentDoc,
  useCreateIntegrationMutation,
  useDeleteIntegrationMutation,
} from "../graphql/generated/apollo";
import { useShopContext } from "../shop";
import { generateAndSetState } from "../utils/auth";

function generateParam(clientId: string, redirectUri: string, scope: Array<string>, state: string, nonce: string) {
  return new URLSearchParams({
    response_type: "code",
    client_id: clientId,
    redirect_uri: redirectUri,
    scope: scope?.join(" "),
    state,
    nonce,
  });
}

export function createShopifyAuthorizaionUrl(
  shopifyStoreId: string,
  callbackUrl: string,
  state: string,
  nonce: string,
) {
  const url = new URL(`https://${shopifyStoreId}.myshopify.com/admin/oauth/authorize`);
  url.search = generateParam(
    import.meta.env.VITE_SHOPIFY_CLIENT_ID,
    callbackUrl,
    [
      "read_products",
      "write_products",
      "read_product_listings",
      "read_publications",
      "write_publications",
      "read_inventory",
      "write_inventory",
      "read_orders",
      "write_orders",
      "read_locations",
    ],
    state,
    nonce,
  ).toString();

  return url;
}

gql`
fragment IntegrationButton_Integration on integrations {
  id
  platform
  disabled_at
  yahoo_shopping_settings {
    id
    seller_id
    public_key
    public_key_version
  }
}
`;

export type IntegrationButtonProps = {
  integration?: IntegrationButton_IntegrationFragment;
  platform: string;
  nonce: string;
  status?: "notConnected" | "available" | "needsReAuth" | "needsAdditionalSetting";
  onStartIntegration?: () => void;
};

export function IntegrationButton(props: IntegrationButtonProps) {
  const { currentShopId, isLoading: isShopLoading } = useShopContext();
  const status = props.status ?? "notConnected";
  const platformLower = props.platform.toLowerCase().replace(/_/g, "-");

  const [showApiKeyForm, setShowApiKeyForm] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const toast = useToast();

  const [createIntegration, { loading }] = useCreateIntegrationMutation();
  const [deleteIntegration] = useDeleteIntegrationMutation({
    variables: {
      shop_id: currentShopId,
      platform: props.platform,
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          integrations(existings = [], { readField }) {
            const affected = data?.delete_integrations?.returning ?? [];
            if (affected?.length === 0) {
              return existings;
            }
            return existings.filter((integ: IntegrationFragment) => readField("id", integ) !== affected[0].id);
          },
        },
      });
    },
    onCompleted() {
      toast({
        title: "連携を解除しました",
        status: "success",
        isClosable: true,
      });
      setShowApiKeyForm(false);
    },
  });

  const startOAuth2Flow = () => {
    // stateとしてランダムな文字列を生成し検証用に保存する
    const { state } = generateAndSetState(platformLower);

    const nonce = props.nonce;

    const callbackUrl = `${import.meta.env.VITE_WEB_DOMAIN.match(/^localhost/) ? "http" : "https"}://${
      import.meta.env.VITE_WEB_DOMAIN
    }/platforms/${platformLower}/auth/callback`;

    switch (props.platform) {
      case "BASE": {
        const url = new URL("https://api.thebase.in/1/oauth/authorize");
        url.search = generateParam(
          import.meta.env.VITE_BASE_CLIENT_ID,
          callbackUrl,
          ["read_items", "write_items", "read_orders", "write_orders"],
          state,
          nonce,
        ).toString();
        window.location.href = url.toString();
        break;
      }
      case "STORES": {
        const url = new URL("https://api.id.stores.jp/oauth2/auth");
        url.search = generateParam(
          import.meta.env.VITE_STORES_CLIENT_ID,
          callbackUrl,
          ["retail.shop.read", "retail.shop.write", "offline_access"],
          state,
          nonce,
        ).toString();
        window.location.href = url.toString();
        break;
      }
      case "YAHOO_SHOPPING": {
        // TODO: 本来は以下のURLから認可URLを取得すべき
        // https://auth.login.yahoo.co.jp/yconnect/v2/.well-known/openid-configuration
        const url = new URL("https://auth.login.yahoo.co.jp/yconnect/v2/authorization");
        url.search = generateParam(
          import.meta.env.VITE_YAHOO_CLIENT_ID,
          callbackUrl,
          ["openid"],
          state,
          nonce,
        ).toString();
        window.location.href = url.toString();
        break;
      }
      case "SHOPIFY": {
        // Shopifyアプリのインストールは本番アプリにリダイレクトされてしまうので開発環境のみ直接認可フローを開始する
        const url =
          process.env.NODE_ENV === "development"
            ? createShopifyAuthorizaionUrl("rakudasu", callbackUrl, state, nonce)
            : new URL("https://apps.shopify.com/rakudasu?locale=ja");
        window.location.href = url.toString();
        break;
      }
    }
  };

  const registerApiKey = () => {
    if (props.onStartIntegration) {
      props.onStartIntegration();
    }

    createIntegration({
      variables: {
        input: {
          shopId: currentShopId,
          platform: props.platform,
          apiKey,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            integrations(existings = []) {
              if (existings.some((integ: IntegrationFragment) => integ.id === data?.createIntegration?.integrationId)) {
                return existings;
              }

              const newInteg = cache.writeFragment({
                id: data?.createIntegration?.integrationId,
                data: {
                  __typename: "integrations",
                  id: data?.createIntegration?.integrationId,
                  shop_id: currentShopId,
                  platform: props.platform,
                  account_name: null,
                  disabled_at: null,
                },
                fragment: IntegrationFragmentDoc,
                fragmentName: "Integration",
              });
              return [...existings, newInteg];
            },
          },
        });
      },
    })
      .then((resp) => {
        if (resp.data?.createIntegration) {
          toast({
            title: "APIキーの登録に成功しました",
            status: "success",
            isClosable: true,
          });
          setApiKey("");
          setShowApiKeyForm(false);
        } else {
          alert("APIキーの登録に失敗しました");
        }
      })
      .catch((e) => {
        console.error(e);
        alert(`APIキーの登録に失敗しました: ${e}`);
      });
  };

  const onClickRegistrationButton = () => {
    if (props.onStartIntegration) {
      props.onStartIntegration();
    }
    switch (props.platform) {
      case "BASE":
      case "STORES":
      case "YAHOO_SHOPPING":
      case "SHOPIFY":
        startOAuth2Flow();
        break;
      case "MERCARI_SHOPS":
        setShowApiKeyForm(true);
        break;
      default:
        throw new Error("unexpected platform");
    }
  };

  const buttonText = () => {
    switch (status) {
      case "notConnected":
        return "連携する";
      case "available":
        return "再連携する";
      case "needsReAuth":
        return "再連携が必要です";
      case "needsAdditionalSetting":
        return "追加で必要な設定をする";
    }
  };

  return showApiKeyForm ? (
    <Box>
      <HStack>
        <Input placeholder="APIアクセストークン" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
        <Button onClick={registerApiKey} colorScheme="teal" isLoading={loading} isDisabled={apiKey === ""}>
          登録する
        </Button>
      </HStack>
      <Box>
        <Text size="xs">
          APIアクセストークンについては
          <Link href="https://support.mercari-shops.com/hc/ja/articles/12290169038617" isExternal>
            メルカリShopsガイド
            <ExternalLinkIcon mx="2px" />
          </Link>
          をご確認ください
        </Text>
      </Box>
    </Box>
  ) : (
    <>
      <Button
        onClick={onClickRegistrationButton}
        colorScheme={status !== "available" ? "teal" : "gray"}
        isLoading={isShopLoading}
      >
        {buttonText()}
      </Button>
      <Button onClick={() => deleteIntegration()} isDisabled={!props.integration} variant="outline" colorScheme="red">
        <CloseIcon />
      </Button>
    </>
  );
}
