import type {
  ExhibitionMercariShopsSettingFragment,
  ExhibitionYahooShoppingSettingFragment,
} from "../graphql/generated/apollo";
import { isRealNumber } from "./number";

const MAX_IMAGE_COUNT_BASE = 20;
const MAX_IMAGE_COUNT_STORES = 15;
const MAX_IMAGE_COUNT_MERCARI_SHOPS = 20;
const MAX_IMAGE_COUNT_YAHOO_SHOPPING = 20;

export type ValidationWarning = {
  field: string;
  reason: string;
  affectedPlatform?: string[];
  affect?: string;
};

export function validate(
  itemName: string,
  itemDescription: string,
  variants: { name: string; stockQuantity: string | number }[],
  itemImages: {
    mime_type?: string;
  }[],
  itemPrice?: number,
  itemCompareAtPrice?: number,
  selectedMarketplaces?: string[],
  mercariShopsSetting?: ExhibitionMercariShopsSettingFragment,
  yahooShoppingSetting?: ExhibitionYahooShoppingSettingFragment,
) {
  const warnings: ValidationWarning[] = [];
  if (itemName === "") {
    warnings.push({
      field: "商品名",
      reason: "未入力",
      affectedPlatform: selectedMarketplaces,
    });
  }
  if (countStringForYahooShopping(itemName) > 150) {
    warnings.push({
      field: "商品名",
      reason: "150文字（全角75文字）を超えている",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["YAHOO_SHOPPING"].indexOf(v) > -1),
      affect: "出品時に商品名が…で省略され、説明の先頭に元の商品名が追加されます",
    });
  }
  if (itemName.length > 100) {
    warnings.push({
      field: "商品名",
      reason: "100文字を超えている",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["STORES"].indexOf(v) > -1),
      affect: "出品時に商品名が…で省略され、説明の先頭に元の商品名が追加されます",
    });
  }
  if (itemName.length > 130) {
    warnings.push({
      field: "商品名",
      reason: "130文字を超えている",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["MERCARI_SHOPS"].indexOf(v) > -1),
      affect: "出品時に商品名が…で省略され、説明の先頭に元の商品名が追加されます",
    });
  }
  if (itemName.length > 255) {
    warnings.push({
      field: "商品名",
      reason: "255文字を超えている",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["BASE"].indexOf(v) > -1),
      affect: "出品時に商品名が…で省略され、説明の先頭に元の商品名が追加されます",
    });
  }
  if (itemDescription === "") {
    warnings.push({
      field: "説明",
      reason: "未入力",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["STORES", "MERCARI_SHOPS"].indexOf(v) > -1),
    });
  }
  if (itemDescription.length > 5000) {
    warnings.push({
      field: "説明",
      reason: "5000文字を超えている",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["YAHOO_SHOPPING"].indexOf(v) > -1),
    });
  }
  if (!itemPrice) {
    warnings.push({
      field: "価格",
      reason: "未入力または0円",
      affectedPlatform: selectedMarketplaces,
    });
  }
  if (!isRealNumber(itemPrice)) {
    warnings.push({
      field: "価格",
      reason: "数値ではない",
      affectedPlatform: selectedMarketplaces,
    });
  }
  if (itemPrice && itemCompareAtPrice && itemPrice > itemCompareAtPrice) {
    warnings.push({
      field: "元価格",
      reason: "セール価格よりも高い",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["STORES", "YAHOO_SHOPPING"].indexOf(v) > -1),
    });
  }
  if (variants.length > 1 && variants.find((v) => v.name === "")) {
    warnings.push({
      field: "種類名",
      reason: "未入力",
      affectedPlatform: selectedMarketplaces,
    });
  }
  if (variants.length > 10) {
    warnings.push({
      field: "種類",
      reason: "10を超えて登録されている",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["MERCARI_SHOPS"].indexOf(v) > -1),
    });
  }
  if (variants.find((v) => !isRealNumber(v.stockQuantity) || v.stockQuantity < 0)) {
    warnings.push({
      field: "在庫",
      reason: "未入力または0未満",
      affectedPlatform: selectedMarketplaces,
    });
  }
  if (itemImages.length === 0) {
    warnings.push({
      field: "画像",
      reason: "1枚以上登録されていない",
      affectedPlatform: selectedMarketplaces?.filter((v) => ["MERCARI_SHOPS", "STORES"].indexOf(v) > -1),
    });
  }
  if (itemImages.length > MAX_IMAGE_COUNT_BASE) {
    warnings.push({
      field: "画像",
      reason: `${MAX_IMAGE_COUNT_BASE}枚を超えて登録されている`,
      affectedPlatform: selectedMarketplaces?.filter((v) => ["BASE"].indexOf(v) > -1),
      affect: `出品時に最初の${MAX_IMAGE_COUNT_BASE}枚までしか表示されません`,
    });
  }
  if (itemImages.length > MAX_IMAGE_COUNT_STORES) {
    warnings.push({
      field: "画像",
      reason: `${MAX_IMAGE_COUNT_STORES}枚を超えて登録されている`,
      affectedPlatform: selectedMarketplaces?.filter((v) => ["STORES"].indexOf(v) > -1),
      affect: `出品時に最初の${MAX_IMAGE_COUNT_STORES}枚までしか表示されません。STORES ベーシックプラン（有料）をご利用中の場合はラクダスまでお問い合わせください`,
    });
  }
  if (itemImages.length > MAX_IMAGE_COUNT_MERCARI_SHOPS) {
    warnings.push({
      field: "画像",
      reason: `${MAX_IMAGE_COUNT_MERCARI_SHOPS}枚を超えて登録されている`,
      affectedPlatform: selectedMarketplaces?.filter((v) => ["MERCARI_SHOPS"].indexOf(v) > -1),
      affect: `出品時に最初の${MAX_IMAGE_COUNT_MERCARI_SHOPS}枚までしか表示されません`,
    });
  }
  if (itemImages.length > MAX_IMAGE_COUNT_YAHOO_SHOPPING) {
    warnings.push({
      field: "画像",
      reason: `${MAX_IMAGE_COUNT_YAHOO_SHOPPING}枚を超えて登録されている`,
      affectedPlatform: selectedMarketplaces?.filter((v) => ["YAHOO_SHOPPING"].indexOf(v) > -1),
      affect: `出品時に最初の${MAX_IMAGE_COUNT_YAHOO_SHOPPING}枚までしか表示されません`,
    });
  }
  if (selectedMarketplaces?.find((v) => v === "MERCARI_SHOPS")) {
    const prefix = "メルカリShopsの";
    if (!mercariShopsSetting) {
      warnings.push({
        field: prefix + "設定",
        reason: "未選択",
        affectedPlatform: ["MERCARI_SHOPS"],
      });
    }
    if (!mercariShopsSetting?.category_id) {
      warnings.push({
        field: prefix + "カテゴリー",
        reason: "未選択",
        affectedPlatform: ["MERCARI_SHOPS"],
      });
    }
    if (!mercariShopsSetting?.condition) {
      warnings.push({
        field: prefix + "状態",
        reason: "未選択",
        affectedPlatform: ["MERCARI_SHOPS"],
      });
    }
    if (!mercariShopsSetting?.shipping_method) {
      warnings.push({
        field: prefix + "配送方法",
        reason: "未選択",
        affectedPlatform: ["MERCARI_SHOPS"],
      });
    }
    if (!mercariShopsSetting?.shipping_from_state_id) {
      warnings.push({
        field: prefix + "発送元の地域",
        reason: "未選択",
        affectedPlatform: ["MERCARI_SHOPS"],
      });
    }
    if (!mercariShopsSetting?.shipping_duration) {
      warnings.push({
        field: prefix + "発送までの日数",
        reason: "未選択",
        affectedPlatform: ["MERCARI_SHOPS"],
      });
    }
  }
  if (selectedMarketplaces?.find((v) => v === "YAHOO_SHOPPING")) {
    const prefix = "Yahoo!ショッピングの";
    if (!yahooShoppingSetting) {
      warnings.push({
        field: prefix + "設定",
        reason: "未選択",
        affectedPlatform: ["YAHOO_SHOPPING"],
      });
    }
    if (!yahooShoppingSetting?.product_category) {
      warnings.push({
        field: prefix + "ショッピングカテゴリ",
        reason: "未選択",
        affectedPlatform: ["YAHOO_SHOPPING"],
      });
    }
    if (
      !yahooShoppingSetting?.store_category_hierarchies ||
      yahooShoppingSetting.store_category_hierarchies.length === 0 ||
      yahooShoppingSetting.store_category_hierarchies[0].length === 0
    ) {
      warnings.push({
        field: prefix + "ストアカテゴリ",
        reason: "未選択",
        affectedPlatform: ["YAHOO_SHOPPING"],
      });
    }
    if (yahooShoppingSetting?.condition && yahooShoppingSetting.condition === 2) {
      if (yahooShoppingSetting?.auction_category === undefined) {
        warnings.push({
          field: prefix + "オークションカテゴリ",
          reason: "未選択",
          affectedPlatform: ["YAHOO_SHOPPING"],
        });
      }
      if (yahooShoppingSetting?.auction_shipping_from_prefecture === undefined) {
        warnings.push({
          field: prefix + "発送元の都道府県",
          reason: "未選択",
          affectedPlatform: ["YAHOO_SHOPPING"],
        });
      }
    }
  }
  return warnings;
}

// 全角を2文字、半角を1文字として文字数をカウント
function countStringForYahooShopping(str: string) {
  // biome-ignore lint/suspicious/noControlCharactersInRegex: より正確でパフォーマンスの良さそうな処理が見つからなかったので
  return str.length + (str.match(/[^\x01-\x7E]/g)?.length ?? 0);
}
