import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text, // Add this line to import the Text component
} from "@chakra-ui/react";
import type React from "react";

interface GeneralModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string | React.ReactNode;
  mainAction: () => void;
  mainActionLabel: string;
  secondAction?: () => void;
  secondActionLabel?: string;
  showCancelButton?: boolean;
  loading?: boolean;
  bodyColor?: string;
}

export const GeneralModal: React.FC<GeneralModalProps> = ({
  isOpen,
  onClose,
  title,
  body,
  mainAction,
  mainActionLabel,
  secondAction,
  secondActionLabel,
  showCancelButton = true,
  loading = false,
  bodyColor,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody whiteSpace="pre-wrap">
          {typeof body === "string" ? <Text color={bodyColor}>{body}</Text> : body}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="yellow" mr={3} onClick={mainAction} isLoading={loading}>
            {mainActionLabel}
          </Button>
          {secondAction && (
            <Button variant="ghost" mr={3} onClick={secondAction} isLoading={loading}>
              {secondActionLabel}
            </Button>
          )}
          {showCancelButton && (
            <Button variant="ghost" onClick={onClose} isLoading={loading}>
              キャンセル
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
