import { gql } from "@apollo/client";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  HStack,
  Heading,
  Img,
  Input,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import yahooShoppingPublicKeyAuthInstruction1 from "../assets/yahoo-shopping-public-key-auth-instruction-1.png";
import yahooShoppingPublicKeyAuthInstruction2 from "../assets/yahoo-shopping-public-key-auth-instruction-2.png";
import yahooShoppingSellerIdInstruction from "../assets/yahoo-shopping-seller-id-instruction.png";
import {
  type IntegrationFragment,
  type YahooShoppingIntegrationSettingsForm_IntegrationYahooShoppingSettingFragment,
  useCreateIntegrationYahooShoppingSettingMutation,
} from "../graphql/generated/apollo";

gql`
fragment YahooShoppingIntegrationSettingsForm_IntegrationYahooShoppingSetting on integration_yahoo_shopping_settings {
  id
  seller_id
  public_key
  public_key_version
}
mutation CreateIntegrationYahooShoppingSetting(
  $input: integration_yahoo_shopping_settings_insert_input = {}
) {
  insert_integration_yahoo_shopping_settings_one(
    object: $input,
    on_conflict: {
      constraint: integration_yahoo_shopping_settings_pkey,
      update_columns: [seller_id, public_key, public_key_version]
    }
  ) {
    id
    integration_id
    seller_id
    public_key
    public_key_version
  }
}
`;

type YahooShoppingIntegrationSettingsFormProps = {
  integrationId: string;
  settings?: YahooShoppingIntegrationSettingsForm_IntegrationYahooShoppingSettingFragment;
  onRegister: () => void;
};

export function YahooShoppingIntegrationSettingsForm({
  integrationId,
  settings,
  onRegister,
}: YahooShoppingIntegrationSettingsFormProps) {
  const [sellerId, setSellerId] = useState<string>(settings?.seller_id ?? "");
  const [publicKey, setPublicKey] = useState<string>(settings?.public_key ?? "");
  const [publicKeyVersion, setPublicKeyVersion] = useState<number>(settings?.public_key_version ?? 1);
  const [createIntegrationYahooShoppingSetting, { loading: loadingCreateIntegrationYahooShoppingSetting }] =
    useCreateIntegrationYahooShoppingSettingMutation();

  const toast = useToast();

  const registerAdditionalSettings = () => {
    createIntegrationYahooShoppingSetting({
      variables: {
        input: {
          id: settings?.id,
          integration_id: integrationId,
          seller_id: sellerId.trim(),
          public_key: publicKey.trim(),
          public_key_version: publicKeyVersion,
        },
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            integrations(existings = []) {
              existings.map((integ: IntegrationFragment) => {
                if (
                  cache.identify(integ)?.endsWith(data?.insert_integration_yahoo_shopping_settings_one?.integration_id)
                ) {
                  return {
                    ...integ,
                    yahoo_shopping_settings: [data?.insert_integration_yahoo_shopping_settings_one],
                  };
                }

                return integ;
              });
            },
          },
        });
      },
    })
      .then((resp) => {
        if (resp.data?.insert_integration_yahoo_shopping_settings_one) {
          toast({
            title: "追加情報の登録に成功しました",
            status: "success",
            isClosable: true,
          });
          setSellerId("");
          onRegister();
        } else {
          alert("追加情報の登録に失敗しました");
        }
      })
      .catch((e) => {
        console.error(e);
        alert(`追加情報の登録に失敗しました: ${e}`);
      });
  };
  return (
    <Stack>
      {(!settings || !settings.seller_id || !settings.public_key) && (
        <Alert status="warning">
          <AlertIcon />
          追加情報の登録が必要です
        </Alert>
      )}
      <Heading size="xs">1. ストアアカウントの登録</Heading>
      <Input placeholder="ストアアカウント" value={sellerId} onChange={(e) => setSellerId(e.target.value)} />
      <Box pt={1}>
        <Img src={yahooShoppingSellerIdInstruction} />
        <Text size="xs">
          「ストアクリエイターPro」に表示されている「ストアアカウント」をご入力ください（上記画像参照）
        </Text>
      </Box>

      <Heading size="xs">2. 公開鍵の登録</Heading>
      <Textarea
        placeholder={"公開鍵\n(-----BEGIN PUBLIC KEY-----\n..."}
        value={publicKey}
        onChange={(e) => setPublicKey(e.target.value)}
      />
      <NumberInput value={publicKeyVersion} onChange={(value) => setPublicKeyVersion(Number(value))}>
        <NumberInputField placeholder="バージョン" />
      </NumberInput>
      <Box pt={1}>
        <Text size="xs">
          「ストアクリエイターPro」の「設定」より「暗号鍵管理」を選択し、以下の手順に従って公開鍵を生成してください。
        </Text>
        <Img src={yahooShoppingPublicKeyAuthInstruction1} />
        <Text size="xs">①「Web APIを利用する」をチェックし、②「公開鍵発行」をクリックする</Text>
        <Img src={yahooShoppingPublicKeyAuthInstruction2} />
        <Text size="xs">①「有効」を選択し、②「コピー」をクリックし、そのまま上記のテキストボックスに貼り付ける</Text>
      </Box>
      <Button
        onClick={registerAdditionalSettings}
        colorScheme="teal"
        isLoading={loadingCreateIntegrationYahooShoppingSetting}
        isDisabled={sellerId === ""}
      >
        登録する
      </Button>
    </Stack>
  );
}
