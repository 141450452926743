// CopiedItemForm.jsx component
// ListingCards componentから呼び出される。そのため、ListingCards componentから渡されたコピー元商品のIDをpropsとして受け取る。
// 受け取ったコピー元商品IDを使ってuseGetItemQueryによって商品情報を取得して、ItemForm componentを呼び出して、その中にコピー元商品の情報を入れる。

import { useGetItemQuery } from "../graphql/generated/apollo";
import { ItemForm } from "./ItemForm"; // ItemTypeもここでインポート

type CopiedItemFormProps = {
  copiedItemId: string;
  inModal?: boolean;
};

export function CopiedItemForm({ copiedItemId, inModal }: CopiedItemFormProps) {
  const { data, loading, error } = useGetItemQuery({
    variables: { id: copiedItemId },
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const copiedItem = structuredClone(data?.items_by_pk);
  if (copiedItem) {
    copiedItem.images = copiedItem.images.map((image) => {
      image.id = undefined;
      return image;
    });
  }

  return <ItemForm initialData={copiedItem ?? undefined} inModal={inModal} />;
}
