import { gql } from "@apollo/client";
import { AddIcon } from "@chakra-ui/icons"; // AttachmentIconは仮決定しているAddIconが本決定したら削除する
import { Box, Button, CloseButton, Flex, Image, Input, Spinner, Text, VStack } from "@chakra-ui/react";
import { DndContext, type DragEndEvent, PointerSensor, closestCenter, useSensor } from "@dnd-kit/core";
import { SortableContext, arrayMove, horizontalListSortingStrategy, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type React from "react";
import { useGenerateSignedUrlMutation, useInsertImagesMutation } from "../graphql/generated/apollo";
import { filename } from "../utils/file";

gql`
mutation GenerateSignedUrl($input: GenerateSignedUrlInput!) {
  generateSignedUrl(input: $input) {
    id
    path
    signedUrl
  }
}
mutation InsertImages($objects: [item_images_insert_input!]!) {
  insert_item_images(objects: $objects) {
    returning {
      id
      path
      position
    }
  }
}
`;

const GCS_BASE_URL = "https://storage.googleapis.com";

export type ItemImage = {
  id?: string;
  file?: File;
  blobUrl?: string;
  base64?: string;
  path?: string;
  mime_type?: string;
  waiting?: boolean;
  loading?: boolean;
};

type ImageUploaderProps = {
  itemImages: ItemImage[];
  onImagesChanged: (itemImages: ItemImage[]) => void;
};

function ImageBlock({
  itemImage,
  index,
  onClickClose,
  changeable,
}: {
  itemImage: ItemImage;
  index: number;
  onClickClose: (index: number) => void;
  changeable?: boolean;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: itemImage.id ?? tempId(itemImage.file),
    disabled: itemImage.loading || itemImage.waiting || !changeable,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    touchAction: "none",
  };

  return (
    <Box
      key={itemImage.id}
      position="relative"
      margin="2"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    >
      <Image
        src={
          itemImage.base64
            ? `data:${itemImage.mime_type};base64,${itemImage.base64}`
            : itemImage.blobUrl
              ? itemImage.blobUrl
              : new URL(`${itemImage.path}/${filename("small", itemImage.mime_type)}`, GCS_BASE_URL).toString()
        }
        alt="Uploaded"
        boxSize="80px"
        objectFit="contain"
      />
      {itemImage.waiting || itemImage.loading ? (
        <Spinner position="absolute" left={0} right={0} top={0} bottom={0} margin="auto" boxSize="12px" />
      ) : changeable ? (
        <CloseButton position="absolute" right="2px" top="2px" onClick={() => onClickClose(index)} />
      ) : null}
    </Box>
  );
}

export function ImageUploader({ itemImages, onImagesChanged }: ImageUploaderProps) {
  const [generateSignedUrlMutation, generateSignedUrlHooks] = useGenerateSignedUrlMutation();
  const [insertImagesMutation, insertImagesHooks] = useInsertImagesMutation();
  const loading = generateSignedUrlHooks.loading || insertImagesHooks.loading;
  const sensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const backup = [...itemImages];
    const files = event.target.files;
    if (!files) {
      console.error("No files selected");
      return;
    }
    const uploadedImages: ItemImage[] = Array.from({ length: files.length });
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // アップロード直後の表示用にblob:~のURLを生成する
      // NOTE: コンポーネントでURL.createObjectURLを呼ぶと再レンダリングされまくるのでここで作っておく
      uploadedImages[i] = { file, blobUrl: URL.createObjectURL(file), waiting: true };
    }
    onImagesChanged([...itemImages, ...uploadedImages]);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      uploadedImages[i].waiting = false;
      uploadedImages[i].loading = true;
      // 1. 署名済みURLを発行
      const resp = await generateSignedUrlMutation({
        variables: {
          input: {
            mimeType: file.type,
          },
        },
      }).catch((e) => {
        console.error(e);
      });
      if (!resp?.data) {
        if (resp?.errors) {
          console.error(resp?.errors);
        }
        alert("画像のアップロードに失敗しました。時間をおいて再度お試しください。");
        return;
      }
      const { id, path, signedUrl } = resp.data.generateSignedUrl;

      // 2. 署名付きURLへのPUTリクエストでGCSにアップロード
      try {
        await fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        });
        console.log(`Uploaded file: ${file.name} -> imageId = ${id}`);

        // アップロード完了情報を配列に追加
        uploadedImages[i] = {
          ...uploadedImages[i],
          id,
          path,
          mime_type: file.type,
        };
      } catch (e) {
        console.error(`ファイル ${file.name} のアップロードに失敗:`, e);
        alert(`画像のアップロードに失敗しました: ${e}`);
        return;
      }
    }
    onImagesChanged([...backup, ...uploadedImages]);

    try {
      await insertImagesMutation({
        variables: {
          objects: uploadedImages.map((image, i) => ({
            id: image.id,
            path: image.path,
            mime_type: image.mime_type,
          })),
        },
      });
    } catch (e) {
      console.error(e);
      alert(`画像のアップロードに失敗しました: ${e}`);
      onImagesChanged(backup);
    }
    onImagesChanged([
      ...backup,
      ...uploadedImages.map((img) => {
        img.loading = false;
        return img;
      }),
    ]);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active && over && active.id !== over.id) {
      const oldIndex = itemImages.findIndex((img) => img.id === active.id);
      const newIndex = itemImages.findIndex((img) => img.id === over.id);
      onImagesChanged(arrayMove(itemImages, oldIndex, newIndex));
    }
  };

  return (
    <Box>
      <Flex direction="row" marginTop="2" alignItems="flex-start" justifyContent="flex-start" flexWrap="wrap">
        <DndContext sensors={[sensor]} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext
            items={itemImages.map((itemImage, i) => itemImage.id ?? i)}
            strategy={horizontalListSortingStrategy}
          >
            {itemImages.map((itemImage, i) => (
              <ImageBlock
                key={itemImage.id ?? i}
                itemImage={itemImage}
                index={i}
                onClickClose={(i) => {
                  const newImages = [...itemImages];
                  newImages.splice(i, 1);
                  onImagesChanged(newImages);
                }}
                changeable={!loading}
              />
            ))}
          </SortableContext>
        </DndContext>
        <Box margin="2">
          <Button
            as="label"
            htmlFor="file-upload"
            alignSelf="flex-start"
            boxSize="80px"
            color="gray.600"
            borderRadius="5%"
            borderStyle="dashed"
            borderWidth="1px"
            borderColor="gray.400"
            position="relative"
            cursor="pointer"
            _before={{
              content: '""',
              backgroundImage: "url(/path/to/your/image)",
              backgroundSize: "cover",
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              opacity: "0.5",
            }}
          >
            {/* iconとtextを縦に並べで中央寄せするためにVStackを使う */}
            <VStack spacing="2" alignItems="center" justifyContent="center">
              {/* <AttachmentIcon boxSize="16px" /> // AddIconとどっちが良いか悩み中。一旦AddIconで様子見。馴染んだらこの行は削除*/}
              {loading ? <Spinner boxSize="12px" /> : <AddIcon boxSize="12px" />}
              <Text fontSize="xs">{itemImages.length > 0 ? "画像を追加" : "画像を登録"}</Text>
            </VStack>
          </Button>
        </Box>
      </Flex>
      <Input
        id="file-upload"
        type="file"
        onChange={handleImageChange}
        accept="image/png,image/jpeg,image/gif"
        disabled={loading}
        hidden
        multiple
      />
    </Box>
  );
}

const tempIdPrefix = "temp-";

function tempId(file?: File) {
  return `${tempIdPrefix}${file?.name || Math.floor(Math.random() * 1000000000)}`;
}
