import { Spinner, Text } from "@chakra-ui/react";
import { useLocation, useParams } from "react-router-dom";
import { useAuthContext } from "../auth";
import HeaderMenu from "../components/HeaderMenu";
import { ItemForm } from "../components/ItemForm";
import { useGetItemQuery } from "../graphql/generated/apollo";
import { useShopContext } from "../shop";

export function Sell() {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location.state ?? {};
  const { currentShopId } = useShopContext();
  const { user } = useAuthContext();
  const skipping = !user || !currentShopId;

  const { data, loading } = useGetItemQuery({
    variables: {
      id: id,
    },
    skip: !id || skipping,
  });

  return (
    <div className="App">
      <HeaderMenu />
      <Text mt="4" pb="2" textAlign="left" fontSize="sm" fontWeight="bold" color="gray.700">
        商品の情報
      </Text>
      {!id ? (
        <ItemForm />
      ) : skipping || loading ? (
        <Spinner />
      ) : data?.items_by_pk ? (
        <ItemForm key={id} id={id} initialData={data.items_by_pk} state={state} />
      ) : (
        <p>商品が見つかりません</p>
      )}
    </div>
  );
}
